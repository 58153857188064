import { Upload, Button, Space, Typography } from "antd";
const { Text } = Typography;

type Props = {
  picon?: any;
  fileData: any;
  text: any;
  isUnderline: Boolean;
  setFileData: (file: any) => void;
};
const defaultProps = {
  isUnderline: true,
};

function UploadLayout({
  fileData,
  text,
  setFileData,
  picon,
  isUnderline,
}: Props & typeof defaultProps) {
  //console.log("picon",picon,text,fileData)
  const props = {
    accept: ".jpg,.png,.pdf",
    onRemove: () => {
      setFileData([]);
    },
    beforeUpload: (file: any) => {
      setFileData(file);
      return false;
    },
    fileData,
  };
  return (
    <Upload {...props} showUploadList={false}>
      <Button
        size="large"
        className={
          (isUnderline ? "underline " : " ") +
          "text-center doctor-secondary-color upload_button "
        }
        type="link"
      >
        <Space size={"small"} align={"center"}>
          {picon}

          <Text style={{ color: "#5AB9B4", fontWeight: 600 }}>{text}</Text>
        </Space>
      </Button>
    </Upload>
  );
}

export default UploadLayout;
